import type React from 'react'
import { useEffect, useState } from 'react'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Create, useForm } from '@refinedev/antd'

import { Form, Input, Checkbox, Space, Select, Button } from 'antd'

import envIntegrityWrap from '../../../server/envIntegrity.js'
import { MinusCircleOutlined } from '@ant-design/icons'

const envIntegrity: Record<string, any> = envIntegrityWrap.envIntegrity

interface EditorProps {
  formProps: any
  bulk?: boolean
  handleOnFinish?: any
}

export const Editor: React.FC<EditorProps> = ({ formProps, bulk = false, handleOnFinish = (v: any) => {} }) => {
  const [launcher, setLauncher] = useState(formProps?.initialValues?.env?.LAUNCHER)
  const [env, setEnv] = useState<any>({ LAUNCHER: undefined })
  const [envArr, setEnvArr] = useState<any>(Object.keys(env).map(key => { return { key, value: env[key] } }))
  const [showScanInterval, setShowScanInterval] = useState(true)
  const [showEnabled, setShowEnabled] = useState(true)

  useEffect(() => {
    const enviroment: any = { LAUNCHER: undefined, ...formProps?.initialValues?.env }
    setEnv(enviroment)
    setLauncher(enviroment.LAUNCHER)
  }, [formProps?.initialValues])

  useEffect(
    () => {
      const envArr = Object.keys(env).map(key => { return { key, value: env[key] } })
      setEnvArr(envArr)
      // formProps?.form?.setFieldsValue({ envArr })
    }
    , [env, formProps?.form]
  )

  const handleLauncherChange = (launcher: string): void => {
    setLauncher(launcher)
    const enviroment: any = { LAUNCHER: launcher }
    for (const key of Object.keys(envIntegrity[launcher])) enviroment[key] = envIntegrity[launcher][key][0]
    setEnv(enviroment)
  }

  const launchers = Object.keys(envIntegrity).map(launcher => ({ value: launcher }))

  const handleReset = (): void => {
    const enviroment: any = { LAUNCHER: undefined, ...formProps?.initialValues?.env }
    setEnv(enviroment)
    setLauncher(enviroment.LAUNCHER)
    setShowScanInterval(true)
    setShowEnabled(true)
  }

  return (
    <Form
      layout='vertical'
      onFinish={handleOnFinish}
      {...formProps}
    >
      {!bulk && (
        <Form.Item
          label='🎫 Instance'
          name={['instance']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.List name={['envArr']}>
        {(fields, { add, remove }) => (
          <>
            {Object.keys(env).map((key, index) => (
              <Space key={index} align='baseline' style={{ display: 'flex', marginBottom: 8 }}>
                <Form.Item
                  label='🔧 Key'
                  name={[index, 'key']}
                  initialValue={key}
                  rules={[{ required: true }]}
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label='🔐 Value'
                  name={[index, 'value']}
                  rules={[{ required: true }]}
                  initialValue={key === 'LAUNCHER' ? launcher : env[key]}
                >
                  <Select
                    style={{ minWidth: 200 }}
                    placeholder='Please select a value'
                    options={
                      key === 'LAUNCHER'
                        ? launchers
                        : envIntegrity[launcher]?.[key]?.filter((e: string) => e).map((value: string) => ({ value }))
                    }
                    onChange={(value: string) => {
                      if (key === 'LAUNCHER') {
                        handleLauncherChange(value)
                      } else {
                        setEnv({ ...env, [key]: value })
                      }
                    }}
                  />
                </Form.Item>
                {bulk &&
                  <MinusCircleOutlined onClick={() => {
                    remove(index)
                    setEnv(
                      envArr
                        .filter((_: any, i: number) => i !== index)
                        .reduce((acc: any, { key, value }: { key: string, value: any }) => {
                          acc[key] = value
                          return acc
                        }, {})
                    )
                  }}
                  />}
              </Space>
            ))}
          </>
        )}
      </Form.List>

      {showScanInterval && (
        <Space align='baseline'>
          <Form.Item
            label='⏳ Scan Interval'
            name={['scanInterval']}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {bulk && (
            <MinusCircleOutlined onClick={() => { setShowScanInterval(false) }} />
          )}
        </Space>
      )}

      {!bulk && (
        <Form.Item
          label='🔌 Netns'
          name={['netns']}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      )}

      <br />

      {showEnabled && (
        <Space align='baseline'>
          <Form.Item
            label='🖥 Enabled'
            name='enable'
            initialValue={[true]}
          >
            <Checkbox.Group options={[{ label: '🖥 Abilitato', value: true }]} />

          </Form.Item>
          {bulk && (
            <MinusCircleOutlined onClick={() => { setShowEnabled(false) }} />
          )}
        </Space>
      )}

      <Form.Item style={{ textAlign: 'right' }}>
        <Button onClick={handleReset}>
          Reset
        </Button>
        {bulk && (

          <Button style={{ marginLeft: 5 }} type='primary' htmlType='submit'>
            Edit Bulk
          </Button>

        )}
      </Form.Item>

    </Form>
  )
}

export const StatusCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    errorNotification: (error, values, resource) => {
      console.log(error)
      return {
        message: 'Failed',
        description: error?.response?.data?.error,
        type: 'error'
      }
    }
  })

  const title = location.pathname.includes('edit')
    ? 'Edit'
    : location.pathname.includes('clone')
      ? 'Clone'
      : 'Create'

  return (
    <Create saveButtonProps={saveButtonProps} title={title}>
      <Editor formProps={formProps} />
    </Create>
  )
}
