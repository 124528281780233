/**
 * Questo file contiene la definizione delle variabili d'ambiente per ogni servizio.
 * La struttura è la seguente:
 * nomeLauncher: {
 *  nomeVariabile: [valore1, valore2, ..., valoreN]
 * }
 *
 * Se nel valore è presente null, significa che la variabile d'ambiente non è obbligatoria.
 *
 */

const envIntegrity = {
  eventimScanner: {
    PLATFORM: ['eventim'],
    ROLE: ['scanner'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    SCANNER: ['puppeteer'],
    CHROME: ['default', 'adspower', 'adspower-xvfb', 'chromium', null],
    PROXY: ['no', 'proxy', null],
    BLOCK_RESOURCE: ['no', 'yes', null]
  },
  fansaleScanner: {
    PLATFORM: ['fansale'],
    ROLE: ['scanner'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    SCANNER: ['human'],
    AUTO_NEW_IP: ['same-ip', 'auto-new-ip'],
    CHROME: ['default', 'adspower', 'adspower-xvfb', 'chromium', null],
    PROXY: ['proxy', 'no', null],
    BLOCK_RESOURCE: ['yes', 'no', null]
  },
  fansaleEventScanner: {
    PLATFORM: ['fansaleEvent'],
    ROLE: ['scanner'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    SCANNER: ['puppeteer'],
    AUTO_NEW_IP: ['same-ip', 'auto-new-ip'],
    CHROME: ['default', 'adspower', 'adspower-xvfb', 'chromium', null],
    PROXY: ['no', 'proxy', null],
    BLOCK_RESOURCE: ['no', 'yes', null]
  },
  ticketmasterScanner: {
    PLATFORM: ['ticketmaster'],
    ROLE: ['scanner'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    SCANNER: ['puppeteer']
  },
  vivaticketScanner: {
    PLATFORM: ['vivaticket'],
    ROLE: ['scanner'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    SCANNER: ['puppeteer'],
    BLOCK_RESOURCE: ['no', 'yes', null]
  },
  vivaresaleScanner: {
    PLATFORM: ['vivaresale'],
    ROLE: ['scanner'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    SCANNER: ['puppeteer'],
    CHROME: ['default', 'adspower', 'adspower-xvfb', null]
  },
  fansaleCarter: {
    PLATFORM: ['fansale'],
    ROLE: ['carter'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible'],
    CART_TYPE: ['evaluate', 'interceptor'],
    RESTART: ['yes', 'no'],
    CHROME: ['default', 'adspower', 'adspower-xvfb', 'chromium', null],
    PROXY: ['no', 'proxy', null],
    TELEGRAM_BOT: ['default', 'cart1', 'cart2', 'cart3', 'cart4', 'cart5', 'cart6', 'cart7', 'cart8', 'phone'],
    TELEGRAM_THREAD: ['General', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'L', 'M']
  },
  ticketmasterCarter: {
    PLATFORM: ['ticketmaster'],
    ROLE: ['carter'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible']
  },
  eventimCarter: {
    PLATFORM: ['eventim'],
    ROLE: ['carter'],
    COUNTRY: ['it'],
    HEADLESS: ['headless', 'visible']
  },
  api: {},
  watchdog: {
    ROLE: ['watchdog'],
    NETNS_CONTROLLER: ['no', 'yes'],
    DEAD_NOTIFY: ['no', 'yes'],
    CLEANER: ['yes', 'no']
  },
  eventimBuyer: {
    PLATFORM: ['eventim'],
    ROLE: ['buyer'],
    COUNTRY: ['it']
  },
  misterAlert: {
    ROLE: ['misterAlert']
  },
  automatch: {
    ROLE: ['automatch']
  }
}

module.exports = { envIntegrity }
